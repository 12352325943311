import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, CircularProgress, Link } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchTasks } from '../models/taskModel';
import { fetchArticles } from '../models/articleModel';

const TaskDetails = () => {
  const { propertyId, taskId } = useParams();
  const navigate = useNavigate();
  const [task, setTask] = useState(null);
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch task and associated articles
    Promise.all([fetchTasks(), fetchArticles()]).then(([tasks, allArticles]) => {
      const selectedTask = tasks.find((t) => t.id.toString() === taskId);
      setTask(selectedTask);

      // Filter articles related to this task
      if (selectedTask?.articleIds) {
        const relatedArticles = allArticles.filter((article) =>
          selectedTask.articleIds.includes(article.id)
        );
        setArticles(relatedArticles);
      }
      setIsLoading(false);
    });
  }, [taskId]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!task) {
    return (
      <Box padding={3}>
        <Typography variant="h6">Task not found.</Typography>
        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Box>
    );
  }

  return (
    <Box padding={3}>
      <Button variant="text" onClick={() => navigate(`/property/${propertyId}`)} sx={{ marginBottom: 2 }}>
        Back to Tasks
      </Button>

      <Typography variant="h4" gutterBottom>
        Task Details
      </Typography>
      <Typography>
        <strong>Title:</strong> {task.title}
      </Typography>
      <Typography>
        <strong>Due Date:</strong> {task.dueDate}
      </Typography>
      <Typography>
        <strong>Priority:</strong> {task.priority}
      </Typography>
      <Typography>
        <strong>Status:</strong> {task.status}
      </Typography>
      <Typography>
        <strong>Owner:</strong> {task.owner}
      </Typography>
      {task.completedDate && (
        <Typography>
          <strong>Completed Date:</strong> {task.completedDate}
        </Typography>
      )}

      {/* Related Articles */}
      <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
        Related Articles
      </Typography>
      {articles.length > 0 ? (
        articles.map((article) => (
          <Box key={article.id} sx={{ marginBottom: 2 }}>
            <Link href={`/articles/${article.id}`} underline="hover">
              {article.title} ({article.category})
            </Link>
          </Box>
        ))
      ) : (
        <Typography>No related articles.</Typography>
      )}
    </Box>
  );
};

export default TaskDetails;
