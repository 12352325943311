import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, Select, MenuItem, Checkbox, List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import { fetchArticles, predefinedCategories } from '../models/articleModel';

const AddTaskModal = ({ open, onClose, onSave, task, owners }) => {
  const [title, setTitle] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [priority, setPriority] = useState('');
  const [owner, setOwner] = useState('');
  const [articleIds, setArticleIds] = useState([]);
  const [category, setCategory] = useState('');
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);

  useEffect(() => {
    if (task) {
      setTitle(task.title);
      setDueDate(task.dueDate);
      setPriority(task.priority);
      setOwner(task.owner);
      setArticleIds(task.articleIds || []);
    } else {
      setTitle('');
      setDueDate('');
      setPriority('');
      setOwner('');
      setArticleIds([]);
    }

    // Fetch articles
    fetchArticles().then((data) => setArticles(data));
  }, [task]);

  useEffect(() => {
    // Filter articles by selected category
    if (category) {
      setFilteredArticles(articles.filter((article) => article.category === category));
    } else {
      setFilteredArticles(articles);
    }
  }, [category, articles]);

  const handleSave = () => {
    const newTask = {
      ...task,
      title,
      dueDate,
      priority,
      owner,
      articleIds,
      id: task?.id || Date.now(),
    };
    onSave(newTask);
    onClose();
  };

  const handleArticleSelection = (id) => {
    setArticleIds((prev) =>
      prev.includes(id) ? prev.filter((articleId) => articleId !== id) : [...prev, id]
    );
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          backgroundColor: 'white',
          width: { xs: '90%', md: 500 },
          margin: '5% auto',
          borderRadius: 2,
          maxHeight: '90vh', // Limit height
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            padding: 2,
            overflowY: 'auto', // Enable scrolling
            flexGrow: 1, // Allow content to grow and scroll
          }}
        >
          <Typography variant="h6" gutterBottom>
            {task ? 'Edit Task' : 'Add Task'}
          </Typography>

          {/* Title Input */}
          <TextField
            label="Title"
            fullWidth
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{ marginBottom: 2 }}
          />

          {/* Due Date Input */}
          <TextField
            label="Due Date"
            type="date"
            fullWidth
            variant="outlined"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ marginBottom: 2 }}
          />

          {/* Priority Input */}
          <TextField
            label="Priority"
            fullWidth
            variant="outlined"
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
            sx={{ marginBottom: 2 }}
          />

          {/* Owner Selection */}
          <Select
            value={owner}
            onChange={(e) => setOwner(e.target.value)}
            displayEmpty
            fullWidth
            variant="outlined"
            sx={{ marginBottom: 2 }}
          >
            <MenuItem value="">
              <em>Select Owner</em>
            </MenuItem>
            {owners.map((owner) => (
              <MenuItem key={owner} value={owner}>
                {owner}
              </MenuItem>
            ))}
          </Select>

          {/* Category Filter */}
          <Select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            displayEmpty
            fullWidth
            variant="outlined"
            sx={{ marginBottom: 2 }}
          >
            <MenuItem value="">
              <em>All Categories</em>
            </MenuItem>
            {predefinedCategories.map((cat) => (
              <MenuItem key={cat} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </Select>

          {/* Article Selection */}
          <Typography variant="subtitle1" gutterBottom>
            Link Articles:
          </Typography>
          <List>
            {filteredArticles.map((article) => (
              <ListItem
                key={article.id}
                button
                onClick={() => handleArticleSelection(article.id)}
              >
                <ListItemText primary={article.title} secondary={article.category} />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    checked={articleIds.includes(article.id)}
                    onChange={() => handleArticleSelection(article.id)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>

        {/* Sticky Action Buttons */}
        <Box
          sx={{
            padding: 2,
            borderTop: '1px solid #ddd',
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: 'white',
          }}
        >
          <Button onClick={onClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={!title || !dueDate || !priority || !owner}
          >
            {task ? 'Update' : 'Add'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddTaskModal;
