import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, CircularProgress, Button } from '@mui/material';
import { fetchProperties } from '../models/propertyModel';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch all properties
    fetchProperties().then((data) => {
      setProperties(data);
      setIsLoading(false);
    });
  }, []);

  return (
    <Box padding={3}>
      <Typography variant="h4" gutterBottom>
        Properties
      </Typography>

      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      ) : properties.length > 0 ? (
        properties.map((property) => (
          <Card key={property.id} sx={{ marginBottom: 2 }}>
            <CardContent>
              <Typography variant="h6">{property.name}</Typography>
              <Typography variant="body2" color="text.secondary">
                {property.description}
              </Typography>
              <Button
                variant="contained"
                size="small"
                sx={{ marginTop: 2 }}
                onClick={() => navigate(`/property/${property.id}`)}
              >
                View Tasks
              </Button>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography>No properties found.</Typography>
      )}
    </Box>
  );
};

export default Home;
