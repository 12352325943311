// src/models/articleModel.js

// Predefined Categories
export const predefinedCategories = ['Gardening', 'Pool', 'Electricity', 'Plumbing', 'General'];

// Article Status
export const ArticleStatus = {
  ACTIVE: 'Active',
  DISABLED: 'Disabled',
};

// Mock API to fetch articles
export const fetchArticles = () => {
  const articles = [
    {
      id: 1,
      title: 'How to Maintain Your Garden',
      content: 'Detailed gardening tips...',
      category: 'Gardening',
      propertyId: null, // General article
      status: ArticleStatus.ACTIVE,
    },
    {
      id: 2,
      title: 'Pool Maintenance 101',
      content: 'Everything you need to know about pool care...',
      category: 'Pool',
      propertyId: 1, // Specific to property ID 1
      status: ArticleStatus.ACTIVE,
    },
  ];

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(articles);
    }, 1000);
  });
};

// Mock API to save an article
export const saveArticle = (article) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(article); // Simulates returning the saved article
    }, 500);
  });
};

// Mock API to disable an article
export const disableArticle = (articleId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(); // Simulates successful disabling of the article
    }, 500);
  });
};
