import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import PropertyTasks from './pages/PropertyTasks';
import TaskDetails from './pages/TaskDetails';
import Articles from './pages/Articles';
import ArticleDetails from './pages/ArticleDetails';
import { TaskProvider } from './contexts/TaskContext';

const App = () => {
  return (
    <TaskProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/property/:propertyId" element={<PropertyTasks />} />
          <Route path="/property/:propertyId/task/:taskId" element={<TaskDetails />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/articles/:articleId" element={<ArticleDetails />} />
        </Routes>
      </Router>
    </TaskProvider>
  );
};

export default App;
