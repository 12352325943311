// src/pages/Articles.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Card,
  CardContent,
} from '@mui/material';
import {
  fetchArticles,
  predefinedCategories,
  ArticleStatus,
  saveArticle,
  disableArticle,
} from '../models/articleModel';
import AddArticleModal from '../components/AddArticleModal';
import { useNavigate } from 'react-router-dom';

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentArticle, setCurrentArticle] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchArticles().then((data) => {
      setArticles(data);
      setFilteredArticles(data);
      setIsLoading(false);
    });
  }, []);

  const handleSearch = (query) => {
    setSearchQuery(query);
    filterArticles(query, selectedCategory);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    filterArticles(searchQuery, category);
  };

  const filterArticles = (query, category) => {
    let filtered = articles.filter((article) => article.status === ArticleStatus.ACTIVE);

    if (category) {
      filtered = filtered.filter((article) => article.category === category);
    }

    if (query) {
      filtered = filtered.filter(
        (article) =>
          article.title.toLowerCase().includes(query.toLowerCase()) ||
          article.content.toLowerCase().includes(query.toLowerCase())
      );
    }

    setFilteredArticles(filtered);
  };

  const handleSaveArticle = (article) => {
    setIsLoading(true);
    saveArticle(article).then((savedArticle) => {
      setArticles((prevArticles) => {
        const existingIndex = prevArticles.findIndex((a) => a.id === savedArticle.id);
        if (existingIndex >= 0) {
          const updatedArticles = [...prevArticles];
          updatedArticles[existingIndex] = savedArticle;
          return updatedArticles;
        } else {
          return [...prevArticles, savedArticle];
        }
      });
      setIsLoading(false);
      setModalOpen(false);
      filterArticles(searchQuery, selectedCategory);
    });
  };

  const handleDisableArticle = (articleId) => {
    setIsLoading(true);
    disableArticle(articleId).then(() => {
      setArticles((prevArticles) =>
        prevArticles.map((article) =>
          article.id === articleId ? { ...article, status: ArticleStatus.DISABLED } : article
        )
      );
      setIsLoading(false);
      filterArticles(searchQuery, selectedCategory);
    });
  };

  return (
    <Box padding={3}>
      <Typography variant="h4" gutterBottom>
        Articles
      </Typography>

      <Box display="flex" flexWrap="wrap" justifyContent="space-between" marginBottom={3}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          sx={{ marginRight: 2, flex: 1, minWidth: 200, marginBottom: { xs: 2, md: 0 } }}
        />
        <Select
          value={selectedCategory}
          onChange={(e) => handleCategoryChange(e.target.value)}
          displayEmpty
          variant="outlined"
          sx={{ marginRight: 2, flex: 1, minWidth: 200, marginBottom: { xs: 2, md: 0 } }}
        >
          <MenuItem value="">
            <em>All Categories</em>
          </MenuItem>
          {predefinedCategories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
        <Button variant="contained" color="primary" onClick={() => setModalOpen(true)}>
          Add Article
        </Button>
      </Box>

      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="40vh">
          <CircularProgress />
        </Box>
      ) : (
        filteredArticles.map((article) => (
          <Card
            key={article.id}
            sx={{ marginBottom: 2, cursor: 'pointer' }}
            onClick={() => navigate(`/articles/${article.id}`)}
          >
            <CardContent>
              <Typography variant="h6">{article.title}</Typography>
              <Typography variant="body2">Category: {article.category}</Typography>
              <Typography variant="body2" sx={{ marginTop: 1 }}>
                {article.content.substring(0, 100)}...
              </Typography>
              <Box display="flex" marginTop={2}>
                <Button
                  variant="text"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrentArticle(article);
                    setModalOpen(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDisableArticle(article.id);
                  }}
                >
                  Disable
                </Button>
              </Box>
            </CardContent>
          </Card>
        ))
      )}

      {/* Add/Edit Article Modal */}
      <AddArticleModal
        open={isModalOpen}
        onClose={() => {
          setModalOpen(false);
          setCurrentArticle(null);
        }}
        onSave={handleSaveArticle}
        article={currentArticle}
        categories={predefinedCategories}
      />
    </Box>
  );
};

export default Articles;
