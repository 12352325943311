import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, CircularProgress, Button } from '@mui/material';
import { fetchProperties, fetchPropertyTasks } from '../models/propertyModel';
import { useParams, useNavigate } from 'react-router-dom';
import AddTaskModal from '../components/AddTaskModal';
import { handleSaveTask, getTodayTasks, getNextWeekTasks } from '../utils/taskUtils';
import { predefinedOwners } from '../utils/taskUtils';
import { useTaskContext } from '../contexts/TaskContext';

const PropertyTasks = () => {
  const { propertyId } = useParams();
  const navigate = useNavigate();
  const { tasks, setTasks, updateTask } = useTaskContext();
  const [propertyName, setPropertyName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);

  useEffect(() => {
    // Fetch property details
    fetchProperties().then((properties) => {
      const property = properties.find((p) => p.id.toString() === propertyId);
      setPropertyName(property?.name || 'Unknown Property');
    });

    // Fetch tasks for the property
    fetchPropertyTasks(propertyId).then((data) => {
      setTasks(data);
      setIsLoading(false);
    });
  }, [propertyId, setTasks]);

  const saveTask = (task) => {
    const updatedTasks = handleSaveTask(tasks, task);
    setTasks(updatedTasks);
    updateTask(task); // Update task in the shared context
    setModalOpen(false);
  };

  const handleTaskUpdate = (updatedTask) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) => (task.id === updatedTask.id ? updatedTask : task))
    );
    updateTask(updatedTask); // Reflect updates in the context
  };

  const markAsCompleted = (taskId) => {
    const taskToUpdate = tasks.find((task) => task.id === taskId);
    if (taskToUpdate) {
      const updatedTask = { ...taskToUpdate, status: 'Completed' };
      handleTaskUpdate(updatedTask);
    }
  };

  const markAsNotNeeded = (taskId) => {
    const taskToUpdate = tasks.find((task) => task.id === taskId);
    if (taskToUpdate) {
      const updatedTask = { ...taskToUpdate, status: 'Not Needed' };
      handleTaskUpdate(updatedTask);
    }
  };

  const todayTasks = getTodayTasks(tasks);
  const nextWeekTasks = getNextWeekTasks(tasks);

  return (
    <Box padding={3}>
      {/* Back Button */}
      <Button variant="text" onClick={() => navigate('/')} sx={{ marginBottom: 2 }}>
        Back to Properties
      </Button>

      {/* Property Title */}
      <Typography variant="h4" gutterBottom>
        Tasks for {propertyName}
      </Typography>

      {/* Add Task Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setCurrentTask(null);
          setModalOpen(true);
        }}
        sx={{ marginBottom: 3 }}
      >
        Add Task
      </Button>

      {/* Loading State */}
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Today's Tasks */}
          <Card sx={{ marginBottom: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Today's Tasks
              </Typography>
              {todayTasks.length > 0 ? (
                todayTasks.map((task) => (
                  <Box
                    key={task.id}
                    sx={{
                      marginBottom: 2,
                      padding: 2,
                      border: '1px solid #ccc',
                      borderRadius: 4,
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate(`/property/${propertyId}/task/${task.id}`)} // Navigate to task details
                  >
                    <Typography variant="body1">
                      - {task.title} (Status: {task.status})
                    </Typography>
                    <Typography variant="body2">Priority: {task.priority}</Typography>
                    <Box display="flex" gap={2} marginTop={1}>
                      <Button
                        variant="contained"
                        size="small"
                        color="success"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent navigation
                          markAsCompleted(task.id);
                        }}
                      >
                        Mark as Completed
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent navigation
                          markAsNotNeeded(task.id);
                        }}
                      >
                        Not Needed
                      </Button>
                    </Box>
                  </Box>
                ))
              ) : (
                <Typography>No tasks for today.</Typography>
              )}
            </CardContent>
          </Card>

          {/* Next Week's Tasks */}
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Next Week's Tasks
              </Typography>
              {nextWeekTasks.length > 0 ? (
                nextWeekTasks.map((task) => (
                  <Box
                    key={task.id}
                    sx={{
                      marginBottom: 2,
                      padding: 2,
                      border: '1px solid #ccc',
                      borderRadius: 4,
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate(`/property/${propertyId}/task/${task.id}`)} // Navigate to task details
                  >
                    <Typography variant="body1">
                      - {task.title} (Status: {task.status})
                    </Typography>
                    <Typography variant="body2">Priority: {task.priority}</Typography>
                    <Box display="flex" gap={2} marginTop={1}>
                      <Button
                        variant="contained"
                        size="small"
                        color="success"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent navigation
                          markAsCompleted(task.id);
                        }}
                      >
                        Mark as Completed
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent navigation
                          markAsNotNeeded(task.id);
                        }}
                      >
                        Not Needed
                      </Button>
                    </Box>
                  </Box>
                ))
              ) : (
                <Typography>No tasks for next week.</Typography>
              )}
            </CardContent>
          </Card>
        </>
      )}

      {/* Add/Edit Task Modal */}
      <AddTaskModal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSave={saveTask}
        task={currentTask}
        owners={predefinedOwners}
      />
    </Box>
  );
};

export default PropertyTasks;
