// src/utils/taskUtils.js

// Predefined Owners
export const predefinedOwners = ['John Doe', 'Jane Smith', 'Alice Brown', 'Bob Johnson'];

// Function to create or update a task
export const handleSaveTask = (tasks, task) => {
  if (task.id) {
    // Update existing task
    return tasks.map((t) => (t.id === task.id ? task : t));
  } else {
    // Add new task
    const newId = tasks.length ? Math.max(...tasks.map((t) => t.id)) + 1 : 1;
    return [
      ...tasks,
      { ...task, id: newId, status: 'Pending', completedDate: null },
    ];
  }
};

// Function to mark a task as completed
export const markTaskAsDone = (tasks, taskId) => {
  const currentDate = new Date().toISOString().split('T')[0];
  return tasks.map((task) =>
    task.id === taskId
      ? { ...task, status: 'Completed', completedDate: currentDate }
      : task
  );
};

// Function to mark a task as "Not Needed"
export const markTaskAsNotNeeded = (tasks, taskId) => {
  return tasks.map((task) =>
    task.id === taskId ? { ...task, status: 'Not Needed' } : task
  );
};

// Function to count tasks by status
export const countTasksByStatus = (tasks, status) => {
  return tasks.filter((task) => task.status === status).length;
};

// Helper to get tasks due today
export const getTodayTasks = (tasks) => {
  const today = new Date().toISOString().split('T')[0];
  return tasks.filter((task) => task.dueDate === today);
};

// Helper to get tasks due in the next 7 days
export const getNextWeekTasks = (tasks) => {
  const today = new Date();
  const nextWeek = new Date(today);
  nextWeek.setDate(today.getDate() + 7);

  return tasks.filter((task) => {
    const dueDate = new Date(task.dueDate);
    return dueDate > today && dueDate <= nextWeek;
  });
};
