// src/models/taskModel.js

export const fetchTasks = () => {
  const tasks = [
    {
      id: 1,
      title: 'Fix Plumbing',
      dueDate: '2024-12-10',
      priority: 'High',
      status: 'Pending',
      owner: 'John Doe',
      articleIds: [1], // Link to articles by ID
    },
    {
      id: 2,
      title: 'Mow Lawn',
      dueDate: '2024-12-08',
      priority: 'Low',
      status: 'Pending',
      owner: 'Jane Smith',
      articleIds: [2, 3], // Multiple articles linked
    },
  ];

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(tasks);
    }, 1000);
  });
};
