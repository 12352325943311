// src/models/propertyModel.js

// Mock property data
let properties = [
  { id: 1, name: 'Main Street House', description: 'A beautiful house on Main Street.', status: 'Active' },
  { id: 2, name: 'Beach Villa', description: 'A luxurious villa by the beach.', status: 'Active' },
];

// Mock tasks data
let tasks = [
  {
    id: 1,
    propertyId: 1,
    title: 'Fix Plumbing',
    dueDate: '2024-12-10',
    priority: 'High',
    status: 'Pending',
    owner: 'John Doe',
  },
  {
    id: 2,
    propertyId: 1,
    title: 'Paint Fence',
    dueDate: '2024-12-12',
    priority: 'Medium',
    status: 'Pending',
    owner: 'Jane Smith',
  },
  {
    id: 3,
    propertyId: 2,
    title: 'Clean Pool',
    dueDate: '2024-12-15',
    priority: 'Low',
    status: 'Pending',
    owner: 'Sam Brown',
  },
];

// Fetch properties
export const fetchProperties = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(properties);
    }, 1000);
  });
};

// Fetch tasks for a specific property
export const fetchPropertyTasks = (propertyId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const propertyTasks = tasks.filter((task) => task.propertyId.toString() === propertyId.toString());
      resolve(propertyTasks);
    }, 1000);
  });
};

// Save (add/edit) property
export const saveProperty = (property) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const existingIndex = properties.findIndex((p) => p.id === property.id);
      if (existingIndex >= 0) {
        properties[existingIndex] = property;
      } else {
        properties.push(property);
      }
      resolve(property);
    }, 500);
  });
};

// Disable property
export const disableProperty = (propertyId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      properties = properties.map((p) =>
        p.id === propertyId ? { ...p, status: 'Disabled' } : p
      );
      resolve();
    }, 500);
  });
};
