// src/pages/ArticleDetails.js

import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchArticles } from '../models/articleModel';

const ArticleDetails = () => {
  const { articleId } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchArticles().then((articles) => {
      const selectedArticle = articles.find((a) => a.id.toString() === articleId);
      setArticle(selectedArticle);
      setIsLoading(false);
    });
  }, [articleId]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!article) {
    return (
      <Box padding={3}>
        <Typography variant="h6">Article not found.</Typography>
        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Box>
    );
  }

  return (
    <Box padding={3}>
      <Button variant="text" onClick={() => navigate(-1)} sx={{ marginBottom: 2 }}>
        Back
      </Button>
      <Typography variant="h4" gutterBottom>
        {article.title}
      </Typography>
      <Typography variant="body1">{article.content}</Typography>
      <Typography variant="body2" sx={{ marginTop: 2 }}>
        Category: {article.category}
      </Typography>
    </Box>
  );
};

export default ArticleDetails;
