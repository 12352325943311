import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, Select, MenuItem } from '@mui/material';

const AddArticleModal = ({ open, onClose, onSave, article, categories = [], properties = [] }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [propertyId, setPropertyId] = useState('');

  useEffect(() => {
    if (article) {
      setTitle(article.title);
      setContent(article.content);
      setCategory(article.category);
      setPropertyId(article.propertyId || '');
    } else {
      setTitle('');
      setContent('');
      setCategory('');
      setPropertyId('');
    }
  }, [article]);

  const handleSave = () => {
    const newArticle = {
      ...article,
      title,
      content,
      category,
      propertyId: propertyId || null, // General if no property selected
      status: article?.status || 'Active',
      id: article?.id || Date.now(),
    };
    onSave(newArticle);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          backgroundColor: 'white',
          width: { xs: '90%', md: 500 },
          padding: 4,
          margin: '100px auto',
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          {article ? 'Edit Article' : 'Add Article'}
        </Typography>

        {/* Title Field */}
        <TextField
          label="Title"
          fullWidth
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        {/* Category Selection */}
        <Select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          displayEmpty
          fullWidth
          variant="outlined"
          sx={{ marginBottom: 2 }}
        >
          <MenuItem value="">
            <em>Select Category</em>
          </MenuItem>
          {categories.map((cat) => (
            <MenuItem key={cat} value={cat}>
              {cat}
            </MenuItem>
          ))}
        </Select>

        {/* Property Selection */}
        <Select
          value={propertyId || ''}
          onChange={(e) => setPropertyId(e.target.value)}
          displayEmpty
          fullWidth
          variant="outlined"
          sx={{ marginBottom: 2 }}
        >
          <MenuItem value="">
            <em>General Article</em>
          </MenuItem>
          {properties.map((property) => (
            <MenuItem key={property.id} value={property.id}>
              {property.name}
            </MenuItem>
          ))}
        </Select>

        {/* Content Field */}
        <TextField
          label="Content"
          fullWidth
          variant="outlined"
          multiline
          rows={6}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        {/* Action Buttons */}
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={!title || !content || !category}
          >
            {article ? 'Update' : 'Add'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddArticleModal;
